<template>
  <div>
    <WeContainer
      header="true"
      breadCrumb=""
      v-bind:actions="actions"
      v-on:on-click="onActionClick"
    >
      <WeTableSearch
        v-on:search="onSearch"
        v-on:clear="onFilterClear"
        v-bind:showOnLoad="checkFilterStorage()"
      >
        <div slot="detail-search">
          <div class="row mb-3">
            <!-- Left -->
            <div class="col-12 col-lg-6">
              <!-- Name -->
              <div class="d-block mb-3">
                <WeRowInput
                  size="sm"
                  name="product"
                  label="Ürün"
                  placeholder="Ürün Adı"
                  v-model="filter.name"
                />
              </div>
              <!-- Name -->

              <!-- Brand -->
              <div class="d-block mb-3">
                <WeRowInput
                  form="vPaginatedSelect"
                  size="sm"
                  name="brand"
                  label="Marka"
                  placeholder="Marka"
                  v-bind:clearable="true"
                  v-bind:option-prop="brandList"
                  v-bind:has-prev-page="hasPrevPage('brand')"
                  v-bind:has-next-page="hasNextPage('brand')"
                  v-on:goPrevPage="onPrevPageBrand"
                  v-on:goNextPage="onNextPageBrand"
                  v-on:search-result="onSearchBrandFilter"
                  v-model="filter.brand"
                />
              </div>
              <!-- Brand -->

              <!-- Category -->
              <div class="d-block mb-3">
                <WeRowInput
                  form="vMultipleSelect"
                  size="sm"
                  name="category"
                  label="Kategori"
                  placeholder="Kategori"
                  v-bind:option-prop="categoryList"
                  v-model="filter.category"
                />
              </div>
              <!-- Category -->

              <!-- Marketplace -->
              <div class="d-block mb-3">
                <WeRowInput
                  form="vSelect"
                  size="sm"
                  name="marketplace"
                  label="Pazaryeri"
                  placeholder="Pazaryeri"
                  select-label="title"
                  v-bind:disabled="!marketplaceConfig"
                  v-bind:clearable="true"
                  v-bind:option-prop="detailMarketplaceList"
                  v-model="filter.marketplace"
                />
              </div>
              <!-- Marketplace -->
              <div class="d-block mb-3">
                <WeRowInput
                  form="vMultipleSelect"
                  size="sm"
                  name="supplier"
                  label="Tedarikçi"
                  placeholder="Tedarikçi"
                  v-bind:option-prop="suppliers"
                  v-model="filter.supplier"
                />
              </div>

              <!-- Price -->
              <div class="d-block mb-3">
                <div class="form-row align-items-center">
                  <div class="col-12 col-lg-3">
                    <label for="price" class="custom-label">Ürün Fiyatı</label>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="row align-items-center">
                      <div class="col">
                        <WePriceInput
                          v-bind:small-size="true"
                          class="mb-0"
                          placeholder="0,00"
                          v-model="filter.start_price"
                          ref="startPrice"
                        />
                      </div>
                      <span>-</span>
                      <div class="col">
                        <WePriceInput
                          v-bind:small-size="true"
                          class="mb-0"
                          placeholder="0,00"
                          v-model="filter.end_price"
                          ref="endPrice"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Price -->

              <!-- Sabit Fiyat -->
              <div class="d-block mb-3">
                <WeRowInput
                  size="sm"
                  form="vSelect"
                  name="is_active"
                  label="Sabit Fiyat"
                  placeholder="Sabit Fiyat"
                  v-bind:clearable="true"
                  v-bind:option-prop="statusOptions"
                  v-model="filter.fixed_price"
                />
              </div>
              <!-- Sabit Fiyat -->
            </div>
            <!-- Left -->

            <!-- Right -->
            <div class="col-12 col-lg-6">
              <!-- SKU -->
              <div class="d-block mb-3">
                <WeRowInput
                  size="sm"
                  name="sku_no"
                  label="Stok Kodu"
                  placeholder="Stok Kodu"
                  v-model="filter.sku_no"
                />
              </div>
              <!-- SKU -->

              <!-- Barcode -->
              <div class="d-block mb-3">
                <WeRowInput
                  size="sm"
                  name="barcode"
                  label="Barkod"
                  placeholder="Barkod"
                  v-model="filter.barcode"
                />
              </div>
              <!-- Barcode -->

              <!-- Quantity
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="quantity"
                label="Stok Miktarı"
                placeholder="Stok Miktarı"
                v-bind:filter-number="true"
                v-model="filter.quantity"
              />
            </div> -->

              <div class="d-block mb-3">
                <div class="form-row align-items-center">
                  <div class="col-12 col-lg-3">
                    <label for="price" class="custom-label">Stok Miktarı</label>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col">
                        <WeRowInput
                          size="sm"
                          inputClass="ml-1"
                          labelClass="display-none"
                          placeholder="Min"
                          v-bind:filter-number="true"
                          v-model="filter.min_stock"
                        />
                      </div>
                      <div class="col">
                        <WeRowInput
                          size="sm"
                          inputClass="mr-1"
                          labelClass="display-none"
                          placeholder="Max"
                          v-bind:filter-number="true"
                          v-model="filter.max_stock"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Quantity -->

              <!-- Created At -->
              <div class="d-block mb-3">
                <WeRowInput
                  form="date"
                  size="sm"
                  label="Eklenme Tarihi"
                  placeholder="Eklenme Tarihi"
                  v-bind:date-range="true"
                  v-model="filter.date"
                />
              </div>
              <!-- Created At -->

              <!-- Durum -->
              <div class="d-block mb-3">
                <WeRowInput
                  size="sm"
                  form="vSelect"
                  name="is_active"
                  label="Durum"
                  placeholder="Durum"
                  v-bind:clearable="true"
                  v-bind:option-prop="statusOptions"
                  v-model="filter.is_active"
                />
              </div>
              <!-- Durum -->

              <!-- Sabit Stok -->
              <div class="d-block mb-3">
                <WeRowInput
                  size="sm"
                  form="vSelect"
                  name="is_active"
                  label="Sabit Stok"
                  placeholder="Sabit Stok"
                  v-bind:clearable="true"
                  v-bind:option-prop="statusOptions"
                  v-model="filter.fixed_stock"
                />
              </div>
              <!-- Sabit Stok -->

              <!-- Tax -->
              <div class="d-block mb-3">
                <div class="form-row align-items-center">
                  <div class="col-12 col-lg-3">
                    <label for="price" class="custom-label">KDV</label>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col">
                        <WeRowInput
                          size="sm"
                          inputClass="ml-1"
                          labelClass="display-none"
                          placeholder="Min"
                          v-bind:filter-number="true"
                          v-model="filter.start_tax"
                        />
                      </div>
                      <div class="col">
                        <WeRowInput
                          size="sm"
                          inputClass="mr-1"
                          labelClass="display-none"
                          placeholder="Max"
                          v-bind:filter-number="true"
                          v-model="filter.end_tax"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tax -->
            </div>
            <!-- Right -->
          </div>
        </div>
      </WeTableSearch>
      <WeTable
        v-bind:index="false"
        v-bind:data="product.list"
        v-bind:allowSortIndex="false"
        v-bind:columns="getColumns"
        v-bind:componentName="componentName"
        v-bind:actions="tableActions"
        v-bind:exportables="exportables"
        v-bind:ajax="true"
        v-bind:loading="loading"
        v-bind:productColumn="'Ürün Adı'"
        v-bind:table-result="tableResult"
        v-bind:multiLanguage="true"
        v-on:on-action="onAction"
        v-on:on-switch="onSwitch"
        v-on:on-export="onExport"
        v-on:make-request="updateProductList"
        ref="productTable"
      />
      <!-- Export -->
      <WeListExport
        v-if="showListExport"
        v-bind:is-variant="checkVariantAvailable"
        v-bind:required-keys="['sku_no', 'name', 'price', 'quantity']"
        v-bind:useLanguage="true"
        v-bind:fixed-selected="[
          'sku_no',
          'name',
          'marketplaces',
          'quantity',
          'price',
          'is_active',
        ]"
        v-on:close="onCloseExportList"
      />
      <!-- ./Export -->

      <!-- Import -->
      <WeBulkImport
        v-if="showImportModal"
        v-bind:file-language.sync="importLanguage"
        v-bind:submit="importSubmit"
        v-bind:required-keys="['sku_no', 'name', 'price', 'quantity']"
        v-bind:fixed-selected="['sku_no', 'name', 'price', 'quantity']"
        v-bind:use-language="true"
        v-on:close="onCloseImportList"
        v-on:on-change-locale="importLanguage = $event"
      />
      <WeBulkEquipmentCross
        v-if="showEquipmentOrCrossModal"
        v-bind:file-language.sync="importLanguage"
        v-bind:submit="ecImportSubmit"
        v-bind:use-language="true"
        v-on:close="onCloseECImportList"
        v-on:on-change-locale="importLanguage = $event"
      />
      <!-- ./Import -->
    </WeContainer>

    <div class="page-content mt-3" v-if="this.export.state != null">
      <div class="container wide-container mb-5">
        <WeCard :class="'alert-' + this.export.color">
          <div class="row align-items-center">
            <div class="col-12 col-lg-12">
              <span>
                <i :class="this.export.icon"></i>
                <b> Son ürün dışarı aktarma işlem durumu: </b>
                [{{ dateToStr(this.export.date) }}] {{ this.export.message }}
              </span>
              <template v-if="this.export.state == '2'">
                <b>Dökümanlar: </b>[<template
                  v-for="(item, index) in this.export.url"
                  :href="item"
                >
                  <a v-bind:key="index" :href="item">
                    {{ index + 1 }}. dökümanı indir
                  </a>
                  <b v-bind:key="index" v-if="index != getExportURLCount() - 1">
                    |
                  </b> </template
                >]</template
              >
            </div>
          </div>
        </WeCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "List",
  data() {
    return {
      componentName: "productlist",
      loading: false,
      showListExport: false,
      showImportModal: false,
      showEquipmentOrCrossModal: false,
      export: {
        message: null,
        color: null,
        url: null,
        state: null,
        date: null,
      },
      exportDetail: null,
      importSubmit: false,
      ecImportSubmit: false,
      importLanguage: "tr",
      actions: [
        {
          text: "Yeni Ürün",
          path: "products/new",
        },
        {
          text: "Toplu Ürün Güncelle",
          class: "btn btn-indigo mr-md-3",
          icon: "fas fa-sync",
          path: "products/bulk-update",
        },
        {
          text: "Toplu Ürün Yükle",
          class: "btn btn-info alert-info mr-md-3",
          icon: "fas fa-file-excel",
          name: "bulk-import",
        },
       
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "products/trash",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "Aktif",
        },
        {
          id: "0",
          name: "Pasif",
        },
      ],
      copyFilter: {},
      filter: {
        name: null,
        brand: null,
        category: null,
        sku_no: null,
        barcode: null,
        min_stock: null,
        max_stock: null,
        quantity: null,
        fixed_stock: null,
        fixed_price: null,
        supplier: null,
        marketplace: null,
        start_price: helper.setPrice(0),
        end_price: helper.setPrice(0),
        date: {
          start: null,
          end: null,
        },
      },
      filterPaginate: {
        brand: {
          item_count: 1,
          current_page: 1,
          total_page: 1,
          total_items: 1,
        },
      },
      tableActions: [
        {
          icon: "fas fa-edit fa-sm",
          class: "btn-outline-primary",
          action: "product-edit",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "package_barcode", th: "Barkod", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "category", th: "Kategori", type: "string" },
        { name: "tax", th: "KDV", type: "string", width: "5%" },
        { name: "_quantity", th: "Miktar", type: "string", width: "10%" },
        { name: "price", th: "Fiyat", type: "currency" },
        { name: "discount_price", th: "İndirimli Fiyat", type: "currency" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
      exportables: ["excel"],
    };
  },
  methods: {
    ...mapActions("session", ["getConfig", "updateConfig"]),
    ...mapActions("product", ["getList", "delete", "isActive"]),
    ...mapActions({
      getBrandList: "brand/getList",
      exportData: "tableExport/exportData",
      importData: "listimport/importData",
      importEquipmentOrCross: "listimport/importEquipmentOrCross",
    }),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row);
      } else if (data.key == "product-edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("products/detail/" + row.id);
    },
    dateToStr() {
      if (this.export.date) {
        return helper.dateToString(this.export.date);
      } else {
        return "";
      }
    },
    onDelete(row) {
      let message = `${row.name} adlı ürünü silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Ürün Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Ürün Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        if (this.marketplaceConfig && this.checkActiveMarketPlaces(data)) {
          if (!data.is_active) {
            this.$swal({
              title:
                "Uyarı: Ürün bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: this.$t("cancel"),
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.setActive(data);
              } else {
                data.row.is_active = true;
                data.is_active = true;
                this.product.list.sort();
              }
            });
          } else {
            this.setActive(data);
          }
        } else {
          this.setActive(data);
        }
      }
    },
    checkActiveMarketPlaces(data) {
      return data.row.marketplace_list.filter((mp) => mp.is_active).length;
    },
    setActive(data) {
      this.isActive({
        form: data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Durum Güncellendi");
          } else {
            this.$toast.error("Durum Güncellenemedi");
          }
        },
        onError: (error) => {
          this.$toast.error("Durum Güncellenemedi");
        },
      });
    },
    onSearch() {
      this.copyFilter = helper.clone(this.filter);
      this.$refs.productTable.currentPage = 1;
      helper.addCacheCurrentFilter(this.copyFilter, this.componentName);
      this.updateProductList({
        page: 1,
        limit: this.$refs.productTable.localPerPage,
      });
    },
    checkFilterStorage() {
      return helper.checkFilterStorage(this.componentName);
    },
    onFilterClear() {
      this.copyFilter = {};
      helper.clearItem(this.filter);
      helper.removeCacheCurrentFilter(this.componentName);
      this.filter.start_price = helper.setPrice(null);
      this.filter.end_price = helper.setPrice(null);
      this.$refs.productTable.currentPage = 1;
      this.$refs.productTable.localPerPage = 25;
      this.updateProductList({
        page: this.$refs.productTable.currentPage,
        limit: this.$refs.productTable.localPerPage,
      });
    },
    getFilter() {
      let copy = this.copyFilter;
      if (!Object.keys(copy).length) {
        return;
      }

      if (copy.start_price && copy.start_price.hasOwnProperty("unmask")) {
        copy.start_price = copy.start_price.unmask || null;
      }
      if (copy.end_price && copy.end_price.hasOwnProperty("unmask")) {
        copy.end_price = copy.end_price.unmask || null;
      }
      copy.date = [];

      if (this.filter.date && this.filter.date.start && this.filter.date.end) {
        Object.entries(this.filter.date).forEach(([key, value]) => {
          if (value) {
            copy.date.push(value);
          }
        });
      }

      return copy;
    },
    updateProductList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      var localFilter = localStorage.getItem(
        `last_${this.componentName}_filter`
      );
      this.filter = helper.loadLocalFilter(this.filter, localFilter);
      this.copyFilter = helper.clone(this.filter);

      //filtreleme tipini api'a gitmeden uygun bir biçime sokuyoruz. marka ve kategori sonradan yüklenen select olduğu için bu zorunlu.
      if (this.copyFilter.brand) {
        if (this.copyFilter.brand.hasOwnProperty("id")) {
          this.copyFilter.brand = this.copyFilter.brand.id;
        }
      }
      if (this.copyFilter.category) {
        if (this.copyFilter.category.hasOwnProperty("id")) {
          this.copyFilter.category = this.copyFilter.category.id;
        }
      }

      this.loading = true;
      this.getList({
        filter: this.getFilter(),
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    checkPermission(code) {
      return permission.check("product", code);
    },
    onExport(data) {
      this.showListExport = true;
      this.exportDetail = data;
    },
    onCloseExportList(data) {
      if (data && data.download) {
        if (this.product.list && this.product.list.length) {
          if (!data.selected.length) {
            this.$toast.error("Lütfen Sütun seçimi yapın");
          } else {
            if (this.exportDetail.name !== "print") {
              this.exportData({
                route: "export-list/products",
                count: this.tableResult.total_items,
                exportable: this.exportDetail,
                fileName: "Ürün Listesi",
                filter: {
                  ...this.getFilter(),
                  useVariant: data.use_variant === true ? 1 : 0,
                  type: this.exportDetail.name,
                  lang: data.language,
                },
                fields: data.selected,
                onSuccess: (result) => {
                  let title, text, icon;
                  switch (result.status) {
                    case "working":
                      title = "Başarısız";
                      text =
                        "Kuyrukta aktarma işlemi mevcut tamamlandıktan sonra tekrar deneyiniz.";
                      icon = "warning";
                      break;
                    case "success":
                      title = "Başarılı";
                      text =
                        "Ürün dışarı aktarma işlemi başladı! Tamamlandığında mail adresinize bildirim gönderilecektir.";
                      icon = "success";
                      break;
                    default:
                      title = "Başarısız";
                      text =
                        "Şuanda işleminizi gerçekleştiremiyoruz daha sonra tekrar deneyiniz.";
                      icon = "warning";
                      break;
                  }
                  this.$swal({
                    title,
                    text,
                    icon,
                    showCancelButton: false,
                    confirmButtonText: "Tamam",
                  }).then((confirm) => {
                    if (confirm.isConfirmed) {
                      window.location.reload();
                    }
                  });
                },
              });
              this.showListExport = false;
            }
          }
        } else {
          this.$swal({
            title: "Dışarı aktarılacak veri mevcut değil",
            icon: "warning",
            confirmButtonText: "Tamam",
          });
        }
      } else {
        this.showListExport = false;
      }
    },
    onActionClick(data) {
      if (data.name == "bulk-import") {
        this.showImportModal = true;
      }
      if ((data.name == "equipment-or-cross-product")) {
        this.showEquipmentOrCrossModal = true;
      }
    },
    onCloseImportList(data) {
      if (data.process && data.file.files.length) {
        if (!data.selected.length) {
          this.$toast.error("Lütfen Sütun seçimi yapın");
        } else {
          let selectedFields = helper.arrayPluck(data.selected, "key");

          if (selectedFields.includes("image")) {
            selectedFields = selectedFields.filter((key) => key !== "image");
            selectedFields.push(
              ...["image_1", "image_2", "image_3", "image_4", "image_5"]
            );
          }

          if (selectedFields.includes("description")) {
            selectedFields = selectedFields.filter(
              (key) => key !== "description"
            );
            selectedFields.push(
              ...["description_1", "description_2", "description_3"]
            );
          }

          this.importSubmit = true;
          this.importData({
            route: "products",
            fields: selectedFields,
            file: data.file.files[0].file,
            lang: this.importLanguage,
            onSuccess: (result) => {
              const status = result.data.status;
              if (status == "error") {
                this.$toast.error("Yükleme sırasında hata oluştu");
              } else {
                this.$swal({
                  title: "Başarılı",
                  text: "Ürün yükleme işlemi sıraya alındı. İşlem tamamlandığında mail adresinize bildirim gönderilecektir.",
                  icon: "success",
                });
              }
            },
            onError: () => {
              this.$toast.error("Yükleme başarısız oldu");
            },
            onFinish: () => {
              this.importSubmit = false;
            },
          });
        }
      } else {
        this.showImportModal = false;
      }
    },
    onCloseECImportList(data) {
      if (data.process && data.file.files.length) {
        this.ecImportSubmit = true;
        this.importEquipmentOrCross({
          file: data.file.files[0].file,
          lang: this.importLanguage,
          type: data.type ? 'equip' : 'cross',
          onSuccess: (result) => {
            const status = result.data.status;
            if (status == "error") {
              this.$toast.error(result?.data?.message ?? "Yükleme sırasında hata oluştu");
            } else {
              this.$swal({
                title: "Başarılı",
                text: "Yükleme işlemi başarılı!",
                icon: "success",
              });
            }
          },
          onError: () => {
            this.$toast.error("Yükleme başarısız oldu");
          },
          onFinish: () => {
            this.ecImportSubmit = false;
          },
        });
      } else {
        this.showEquipmentOrCrossModal = false;
      }
    },
    hasPrevPage(el) {
      const elem = this.filterPaginate[el];
      if (elem) {
        return elem.current_page !== 1;
      } else {
        return false;
      }
    },
    hasNextPage(el) {
      const elem = this.filterPaginate[el];
      if (elem) {
        return elem.current_page < elem.total_page;
      } else {
        return false;
      }
    },
    onPrevPageBrand() {
      const brandFilter = this.filterPaginate.brand;
      const isPrevAvailable = this.hasPrevPage("brand");

      if (isPrevAvailable) {
        this.updateBrandOptions(brandFilter.current_page - 1);
      }
    },
    getExportURLCount() {
      return this.export.url.length;
    },
    onNextPageBrand() {
      const brandFilter = this.filterPaginate.brand;
      const isNextAvailable = this.hasNextPage("brand");

      if (isNextAvailable) {
        this.updateBrandOptions(brandFilter.current_page + 1);
      }
    },
    updateBrandOptions(page = 1) {
      const brandFilter = this.filterPaginate.brand;
      const filter = {
        page,
        limit: 25,
      };

      this.getBrandList({
        table: filter,
        onSuccess: (result) => {
          helper.copy(result.data, brandFilter, ["items", "status"]);
        },
      });
    },
    onSearchBrandFilter(data) {
      this.brandState.list = data;
      this.filterPaginate.brand = {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      };
    },
    setExportState() {
      let state = this.config["last_products_export_status"] ?? null;
      let url = JSON.parse(this.config["last_products_export_url"] ?? []);
      let date = this.config["last_products_export_date"] ?? null;
      let message = null;
      let color = null;
      let icon = null;
      switch (state) {
        case "-1":
          message =
            "Ürün dışarı aktarılırken bir hata oluştu lütfen daha sonra tekrar deneyiniz.";
          color = "danger";
          icon = "fas fa-exclamation-triangle";
          break;
        case "0":
          message =
            "Ürün dışarı aktarma işlemi kuyruğa alındı kısa sürede işlem görecektir.";
          color = "warning";
          icon = "fas fa-clock";
          break;
        case "1":
          message = "Ürün dışarı aktarma işlemi devam ediyor...";
          color = "primary";
          icon = "fas fa-spinner fa-spin";
          break;
        case "2":
          message = "Ürünü dışarı aktarma işlemi tamamlandı!";
          color = "success";
          icon = "fas fa-check-circle";
          break;
      }
      this.export = {
        icon,
        message,
        color,
        url,
        state,
        date,
      };
    },
  },
  computed: {
    ...mapState({
      shared: (state) => state.shared,
      product: (state) => state.product,
      marketplaces: (state) => state.marketplace.list,
      config: (state) => state.session.config,
      category: (state) => state.category.list,
      suppliers: (state) => state.supplier.list,
      brandState: (state) => state.brand,
      brand: (state) => state.brand.list,
    }),
    ...mapState(["session"]),
    checkVariantAvailable() {
      const productVariants = this.config["site.product_variants"];
      return productVariants == "1" || productVariants;
    },
    detailMarketplaceList() {
      let marketplaceList = this.marketplaces;
      let eCommerce = {
        id: "e-commerce",
        title: "Pazaryerinde Olmayanlar",
      };

      const result = [eCommerce, ...marketplaceList];
      return result;
    },
    marketplaceConfig() {
      const integrationKey = this.config["site.marketplace_integration"];
      return integrationKey == "1" || integrationKey == 1;
    },
    getColumns() {
      let columns = this.columns;
      let ismarketPlaceInArray = this.columns.some(
        (c) => c.name == "marketplace_list"
      );
      if (this.marketplaceConfig && !ismarketPlaceInArray) {
        columns.splice(4, 0, {
          name: "marketplace_list",
          th: "Pazaryeri",
          type: "marketplace_list",
        });
      }
      return columns;
    },
    categoryList() {
      let categoryList = this.category;
      const result = [...categoryList];
      result.push({ id: -1, name: "Kategorisiz" });
      return result;
    },
    brandList() {
      let brandList = this.brand;
      const result = [...brandList];
      return result;
    },
  },
  mounted() {
    this.getConfig({
      onSuccess: (result) => {
        this.setExportState();
        if(this.config['app.name_tenant'] == 'ecefilter') {
          this.actions.push(    {
          text: "Ekipman/Çapraz Ürün Yükle",
          class: "btn btn-info alert-info",
          icon: "fas fa-file-excel",
          name: "equipment-or-cross-product",
        })          
        }
      },
    });
    this.$store.dispatch("marketplace/getList");
    this.$store.dispatch("category/getList", "");
    this.$store.dispatch("supplier/getList", "");
    this.updateBrandOptions();
    let locales = this.session?.config["site.available_locales"];
    if (locales != undefined) {
      locales = JSON.parse(locales);
      if (locales.length > 1)
        this.columns.push({ name: "locales", th: "Dil", type: "language" });
    }
  },
};
</script>

<style lang="scss" scoped>
.img-popover {
  color: #2f3542;
}

.img-popover:hover {
  text-decoration: none;
  color: #535455;
}
</style>
